import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

    banners: Array<any> = [
        {
            image: 'assets/images/banners/laptop-min.png',
            title: 'Laptopi',
            subtitle: 'Novi / Korišteni',
            url: '/home/products/laptopi'
        },
        {
            image: 'assets/images/banners/komp-min.png',
            title: 'Kompjuteri',
            subtitle: 'Novi / Korišteni',
            url: '/home/products/kompjuteri'
        },
        {
            image: 'assets/images/banners/toner-min.png',
            title: 'Toneri',
            url: '/home/products/toneri'
        },
        {
            image: 'assets/images/banners/periferija-min.png',
            title: 'Periferija',
            url: '/home/products/periferija'
        },
        {
            image: 'assets/images/banners/printer-min.png',
            title: 'Printeri',
            url: '/home/products/printeri'
        },
        {
            image: 'assets/images/banners/tv-min.png',
            title: 'televizije',
            url: '/home/products/televizije'
        },
    ];
    contentLoaded = false;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.contentLoaded = true;
        }, 1000);
    }

    public getBanner(index) {
        return this.banners[index];
    }

    public getBgImage(index) {
        let bgImage = {
            'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
        };
        return bgImage;
    }
}
