import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {LocalStorageService} from './local-storage.service';
import {ConstService} from './const.service';
import {Category} from '../../../models/category.model';
import {map} from 'rxjs/operators';
import {SidenavMenu} from '../sidebar/sidebar-menu.model';
import {Product} from '../../../models/product.model';
import {HttpResponse} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private api: ApiService
    ) {
        this.getCategoriesFromApi();
    }

    private categories = new BehaviorSubject<Category[]>([]);

    public get CATEGORIES() {
        return this.categories.asObservable();
    }


    private getCategoriesFromApi(): void {
        this.api.getCategories().subscribe(response => {
            this.setCategories(response);
        });
    }

    public setCategories(data): void {
        this.categories.next(data);
        LocalStorageService.setItem(ConstService.CATEGORIES, JSON.stringify(data));
    }

    public search(term: string, pageNumber?: number): Observable<HttpResponse<Product[]>> {
        return this.api.search(term, pageNumber);
    }

    public get MENU_ITEMS(): Observable<SidenavMenu[]> {
        return this.CATEGORIES.pipe(
            map((categories) => {
                    const menuItems = [];
                    for (const category of categories) {
                        menuItems.push({
                            displayName: category.name,
                            route: !(category['subCategories'] && category.subCategories.length) ?
                                '/home/products/' + category.name : '',
                            children: this.createSubCategoryMenuItems(category as unknown as Category)
                        });
                    }
                    return menuItems;
                }
            )
        );
    }

    private createSubCategoryMenuItems(category: Category): SidenavMenu[] {
        const children = [
            {
                displayName: 'Sve',
                route: '/home/products/' + category.name
            },
            {
                displayName: 'Novo',
                route: '/home/products/' + category.name + '/new'
            },
            {
                displayName: 'Korišeteno',
                route: '/home/products/' + category.name + '/used'
            }
        ];
        if (category.subCategories && category.subCategories.length) {

            for (const subCategory of category.subCategories) {
                children.push({
                    displayName: subCategory.name,
                    route: '/home/products/' + subCategory.name
                });
            }

        }
        return children;
    }

    public getProductsOnSale(): Observable<HttpResponse<Product[]>> {
        return this.api.getProductsOnSale();
    }

    public getCarouselProducts(): Observable<HttpResponse<Product[]>> {
        return this.api.getCarouselProducts();
    }

    public getProduct(id): Observable<HttpResponse<Product[]>> {
        return this.api.getProduct(id);
    }
}
